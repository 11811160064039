import modes from '#src/data/modes.js';
import { insuredQuoteData } from '#src/structures/quote-params/quote-params-helpers.js';
import { currencyFormatter, isBoolean } from '#src/util/helpers.js';
import { PRODUCTS } from '#src/structures/ProductType.js';

export const FINAL_EXPENSE_MAX_AGE = 85;
export const FINAL_EXPENSE_MIN_AGE = 45;
export const FINAL_EXPENSE_MAX_FACE_AMOUNT = 50000;
const FORMATTED_FINAL_EXPENSE_MAX_FACE_AMOUNT = currencyFormatter(FINAL_EXPENSE_MAX_FACE_AMOUNT, 0);

export default function FinalExpense() {
  return {
    get type() {
      return PRODUCTS.FINAL_EXPENSE;
    },
    get isFinalExpense() {
      return true;
    },
    showHealth(conversion) {
      return !conversion;
    },
    renderRules(_quoteParams, options = { conversion: false }) {
      return {
        saveAge: true,
        faceAmount: true,
        insuredHealth: this.showHealth(options.conversion),
        guaranteedIssue: true,
        mode: true,
      };
    },
    get display() {
      return 'Final Expense';
    },
    get defaults() {
      return {
        categories: [this.requestCategory],
        save_age: false,
        guaranteed_issue: null,
      };
    },
    get requestCategory() {
      return 'whole_life.final_expense';
    },
    get guaranteedIssueOptions() {
      return [
        {
          text: 'Yes',
          value: true,
        },
        {
          text: 'No',
          value: null,
        },
      ];
    },
    get modes() {
      return modes;
    },
    eAppParser(model = {}) {
      let face_amount = model?.face_amount;
      if (
        !model?.face_amount ||
        model?.face_amount > FINAL_EXPENSE_MAX_FACE_AMOUNT ||
        model?.face_amount < 0
      )
        face_amount = 25000;

      let save_age = model.save_age;
      if (!isBoolean(save_age)) save_age = this.defaults.save_age;

      let guaranteed_issue = model.guaranteed_issue;
      if (!this.guaranteedIssueOptions.some(({ value }) => value === guaranteed_issue)) {
        guaranteed_issue = this.defaults.guaranteed_issue;
      }

      return {
        face_amount,
        save_age,
        guaranteed_issue,
      };
    },
    giveParamsSuggestion(age, params) {
      if (age < FINAL_EXPENSE_MIN_AGE) {
        return `The minimum age for Final Expense is ${FINAL_EXPENSE_MIN_AGE}`;
      } else if (params.face_amount > FINAL_EXPENSE_MAX_FACE_AMOUNT) {
        return `The max death benefit for Final Expense is ${FORMATTED_FINAL_EXPENSE_MAX_FACE_AMOUNT}`;
      }
      return '';
    },
    toQuoteRequest({ eAppParams = {}, insured, agentId }) {
      const paramObj = eAppParams;
      let category = this.requestCategory;
      if (paramObj.guaranteed_issue) {
        category = `${this.requestCategory}.guaranteed_issue`;
      }

      return {
        agent: { id: agentId },
        compare: false,

        ...insuredQuoteData(insured),

        selected_type: eAppParams.selected_type,
        state: eAppParams.state,
        mode: paramObj.mode,
        categories: [category],

        save_age: paramObj.save_age,
        face_amount: paramObj.face_amount,
        guaranteed_issue: paramObj.guaranteed_issue,
      };
    },
  };
}
