import { PRODUCTS } from '#src/structures/ProductType.js';
import { insuredQuoteData } from '#src/structures/quote-params/quote-params-helpers.js';
import { isBoolean } from '#src/util/helpers.js';
import modeOptions from '#src/data/modes.js';
function BaseTermQuoteParams(type) {
  const requestCategories = {
    [PRODUCTS.TERM]: 'term.none',
    [PRODUCTS.NON_MED]: 'term.none.non_med',
  };
  const requestCategory = requestCategories[type];
  const categories = [];
  const fetchableTypes = [];
  if (requestCategory) {
    categories.push(requestCategory);
    fetchableTypes.push(type);
  }
  return {
    requestCategory,
    categories,
    fetchableTypes,
    get type() {
      return type;
    },
    get isTerm() {
      return true;
    },
    permitFetchableType(type) {
      if (!requestCategories[type]) throw 'Must be a supported type';
      fetchableTypes.push(type);
      categories.push(requestCategories[type]);
    },
    showHealth(conversion) {
      return !conversion
    },
    renderRules(_quoteParams,  options = { conversion: false }) {
      return {
        saveAge: true,
        termLength: true,
        faceAmount: true,
        livingBenefits: true,
        insuredHealth: this.showHealth(options.conversion),
        mode: true
      };
    },
    get termLengths() {
      return [40, 35, 30, 25, 20, 15, 10];
    },
    get livingBenefits() {
      return [
        { text: 'Yes', value: true },
        { text: 'No', value: false },
      ];
    },
    get modes() {
      return modeOptions;
    },
    get display() {
      return 'Term';
    },
    get defaults() {
      return {
        term_duration: 20,
        living_benefits: false,
        save_age: false,
        categories: this.categories,
      };
    },
    requestCategoryGenerator(termLength = null) {
      const reqCatGen = (cat, len) => (len ? `${cat}.${len}_year` : cat);
      return this.categories.map((v) => reqCatGen(v, termLength));
    },
    eAppParser(model = {}) {
      let term_duration = model?.term_duration || model?.pay_duration;
      if (!this.termLengths.some((v) => v === term_duration)) {
        term_duration = this.defaults.term_duration;
      }

      let living_benefits = model?.living_benefits;
      if (living_benefits !== true) living_benefits = false;
      let saveAge = model.save_age;
      if (!isBoolean(saveAge)) saveAge = this.defaults.save_age;
      return {
        term_duration,
        living_benefits,
        save_age: saveAge,
        face_amount: model?.face_amount || 1000000,
      };
    },
    giveParamsSuggestion(age) {
      if (age < 18) {
        return 'The minimum age for Term is 18';
      }
      return '';
    },
    toQuoteRequest({ eAppParams = {}, insured, agentId }) {
      const paramObj = eAppParams;
      
      return {
        agent: { id: agentId },
        compare: false,

        ...insuredQuoteData(insured),

        selected_type: eAppParams.selected_type,
        state: paramObj.state,
        categories: this.requestCategoryGenerator(paramObj.term_duration),

        mode: paramObj.mode,
        face_amount: paramObj.face_amount,
        living_benefits: paramObj.living_benefits || undefined,
        save_age: paramObj.save_age,
      };
    },
  };
}

export function Term() {
  return BaseTermQuoteParams(PRODUCTS.TERM);
}

export function NonMedQuoteParams() {
  return BaseTermQuoteParams(PRODUCTS.NON_MED);
}

export function TermConceptParams() {
  return BaseTermQuoteParams();
}
