import { steps, stepToRouteName } from '#src/enumerations/step-enumeration.js';
import { defineStore } from '#src/stores/state-wrapper.js';

import { useEappStore } from '#src/stores/electronic-application.js';
import { useStepQuoteStore } from '#src/stores/step-quote.js';
import { useStepLifePriorityStore } from '#src/stores/step-life-priority.js';
import { useStepBirthdateStore } from '#src/stores/step-birthdate.js';

export const useStepPreQuoteInformationStore = (pinia, hot) =>
  defineStore('step-pre-quote-information', {
    getters: {
      step: () => steps.PRE_QUOTE_INFORMATION_STEP,
      routeName: (s) => stepToRouteName[s.step],
      next: () => {
        const quote = useStepQuoteStore(pinia);
        return quote.step;
      },
      previous() {
        const birthdate = useStepBirthdateStore(pinia);
        if (birthdate.next === this.step) {
          if (birthdate.inactive) return birthdate.previous;
          return birthdate.step;
        }

        const priority = useStepLifePriorityStore(pinia);
        return priority.step;
      },
      inactive() {
        const birthdate = useStepBirthdateStore(pinia);
        if (birthdate.next === this.step) return false;
        const eAppStore = useEappStore(pinia);
        return eAppStore.assistance.topPriority !== 'income_rider';
      },
    },
  })(pinia, hot);
