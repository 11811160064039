import { closestCalc, isBoolean } from '#src/util/helpers.js';
import { insuredQuoteData } from '#src/structures/quote-params/quote-params-helpers.js';
import { PRODUCTS } from '#src/structures/ProductType.js';
import modes from '#src/data/modes.js';
import { ACCIDENTAL_DEATH_RATES } from '#src/data/quoteParams.js';
export default function AccidentalDeath() {
  return {
    get type() {
      return PRODUCTS.ACCIDENTAL_DEATH;
    },
    get isAccidentalDeath() {
      return true;
    },
    get display() {
      return 'Accidental Death';
    },
    get defaults() {
      return {
        categories: [this.requestCategory],
        save_age: false,
      };
    },
    get requestCategory() {
      return 'accidental_death';
    },
    get faceAmounts() {
      return ACCIDENTAL_DEATH_RATES;
    },
    get modes() {
      return modes;
    },
    showHealth(conversion) {
      return !conversion;
    },
    renderRules(_quoteParams, options = { conversion: false }) {
      return {
        saveAge: true,
        fixedFaceAmount: true,
        insuredHealth: this.showHealth(options.conversion),
        mode: true,
      };
    },
    eAppParser(model = {}) {
      const faceAmount = closestCalc(model.face_amount, this.faceAmounts);
      let saveAge = model.save_age;
      if (!isBoolean(saveAge)) saveAge = this.defaults.save_age;
      return {
        face_amount: faceAmount || 500000,
        save_age: saveAge,
        mode: model?.mode || this.defaults.mode,
      };
    },
    toQuoteRequest({ eAppParams = {}, insured, agentId }) {
      const paramObj = eAppParams;

      return {
        agent: { id: agentId },
        compare: false,
        ...insuredQuoteData(insured),

        state: eAppParams.state,
        categories: this.defaults.categories,
        selected_type: eAppParams.selected_type,

        mode: eAppParams.mode,
        save_age: paramObj.save_age,
        face_amount: paramObj.face_amount,
      };
    },
  };
}
