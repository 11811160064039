import modes from '#src/data/modes.js';
import { insuredQuoteData } from '#src/structures/quote-params/quote-params-helpers.js';
import { isBoolean } from '#src/util/helpers.js';
import { PRODUCTS } from '#src/structures/ProductType.js';

export default function ReturnOfPremium() {
  return {
    showHealth(conversion) {
      return !conversion
    },
    renderRules(_quoteParams, options = { conversion: false }) {
      return {
        saveAge: true,
        termLength: true,
        faceAmount: true,
        insuredHealth: this.showHealth(options.conversion),
        mode: true,
      };
    },
    get isRopTerm() {
      return true;
    },
    get type() {
      return PRODUCTS.ROP_TERM;
    },
    get display() {
      return 'Return of Premium Term';
    },
    get requestCategory() {
      return 'term.return_of_premium';
    },
    get termLengths() {
      return [30, 20];
    },
    get defaults() {
      return {
        term_duration: 20,
        save_age: false,
        categories: this.requestCategoryGenerator,
      };
    },
    get modes() {
      return modes;
    },
    //When is this used?
    requestCategoryGenerator(termLength = null) {
      const reqCatGen = (cat, len) => (len ? `${cat}.${len}_year` : cat);
      return [reqCatGen(this.requestCategory, termLength)];
    },
    eAppParser(model = {}) {
      let term_duration = model?.pay_duration || model?.term_duration;
      if (!this.termLengths.includes(term_duration)) term_duration = this.defaults.term_duration;
      let saveAge = model.save_age;
      if (!isBoolean(saveAge)) saveAge = this.defaults.save_age;
      return {
        term_duration,
        save_age: saveAge,
        face_amount: model?.face_amount || 1000000,
      };
    },
    toQuoteRequest({ eAppParams = {}, insured = {}, agentId = null }) {
      const paramObj = eAppParams;

      return {
        agent: { id: agentId },
        compare: false,

        ...insuredQuoteData(insured),

        selected_type: eAppParams.selected_type,
        state: eAppParams.state,
        categories: this.requestCategoryGenerator(paramObj.term_duration),

        mode: eAppParams.mode,
        save_age: paramObj.save_age,
        face_amount: paramObj.face_amount,
      };
    },
  };
}
