import {
  MONEY_POOL_OPTIONS,
  MONTHLY_BENEFITS,
  ELIMINATION_PERIODS,
  JOINT_INSURED_PARTNER_DISCOUNT,
  DISCOUNT_OPTIONS,
  MONTHLY_BENEFIT_MOST_COMMON,
  SPOUSE_PARTNER_DISCOUNT,
} from '#src/data/quoteParams.js';

import { closestCalc, isBoolean } from '#src/util/helpers.js';
import { insuredQuoteData } from '#src/structures/quote-params/quote-params-helpers.js';
import { BENEFIT_PERIOD_OPTIONS } from '#src/structures/quote-params/LinkedBenefit.js';
import modes from '#src/data/modes.js';
import { PRODUCTS } from '#src/structures/ProductType.js';

export default function LongTermCare() {
  return {
    get display() {
      return 'Long Term Care';
    },
    get type() {
      return PRODUCTS.LTC;
    },
    get isLTC() {
      return true;
    },
    get defaults() {
      return {
        monthly_benefit: MONTHLY_BENEFIT_MOST_COMMON,
        pool_of_money: 200000,
        benefit_period: 4,
        elimination_period: 90,
        pay_duration: -1,
        home_health_care_waiver: false,
        shared_care: false,
        joint_waiver_of_premium: false,
        inflation_percentage: '3% Compound',
        partner_discount: null,
        return_of_premium_rider: null,
        discount: null,
        categories: [this.requestCategory],
        save_age: false,
      };
    },
    get requestCategory() {
      return 'ltc';
    },
    get modes() {
      return modes;
    },
    get eliminationPeriodItems() {
      return ELIMINATION_PERIODS;
    },
    get returnOfPremiumOptions() {
      return [
        {
          value: null,
          text: 'None',
          subtitle: 'You cannot get your LTC insurance premiums back.',
        },
        {
          value: 'Limited',
          text: 'Limited',
          subtitle:
            'Returns the total premiums paid, reduced by claims, to a beneficiary at death of the insured(s).',
        },
        {
          value: 'Limited Surrender',
          text: 'Limited Surrender',
          subtitle:
            'Limited plus up to 80% of the total premiums paid to the policyowner, reduced by claims if the policy is surrendered.',
        },
        {
          value: 'Full',
          text: 'Full',
          subtitle: 'Returns the total premiums paid to a beneficiary at death of the insured(s).',
        },
        {
          value: 'Full Surrender',
          text: 'Full Surrender',
          subtitle:
            'Full plus up to 80% of the total premiums paid to the policyowner, reduced by claims if the policy is surrendered.',
        },
      ];
    },
    get inflationOptions() {
      return [
        { text: 'None', value: null, decimalValue: 0 },
        { text: '1% Compound', value: '1% Compound', decimalValue: 0.01 },
        { text: '2% Compound', value: '2% Compound', decimalValue: 0.02 },
        { text: '3% Compound', value: '3% Compound', decimalValue: 0.03 },
        { text: '4% Compound', value: '4% Compound', decimalValue: 0.04 },
        {
          text: '5% Compound',
          value: '5% Compound',
          decimalValue: 0.05,
        },
      ];
    },
    get payDurationOptions() {
      return [
        {
          value: 1,
          title: 'Single Pay',
        },
        {
          value: 10,
          title: '10 Pay',
        },
        {
          value: -1,
          title: 'Lifetime Pay',
        },
      ];
    },
    get partnerDiscountOptions() {
      return [
        { text: 'Single', value: null, requiresJointInfo: null },
        {
          text: 'Married, Applying with Spouse',
          value: JOINT_INSURED_PARTNER_DISCOUNT,
          requiresJointInfo: 'verbose',
        },
        {
          text: 'Married, Applying Individually',
          value: SPOUSE_PARTNER_DISCOUNT,
          requiresJointInfo: 'basic',
        },
      ];
    },

    get discountOptions() {
      return DISCOUNT_OPTIONS.map(({ text, value }) => ({
        title: text,
        value,
      }));
    },
    showHealth(conversion) {
      return !conversion;
    },
    renderRules(params, options = { conversion: false }) {
      const sharedCare = params.partner_discount === JOINT_INSURED_PARTNER_DISCOUNT;
      const jointPremiumWaiver = params.partner_discount === JOINT_INSURED_PARTNER_DISCOUNT;

      const showHealth = this.showHealth(options.conversion);
      const jointInsuredHealth =
        params.partner_discount === JOINT_INSURED_PARTNER_DISCOUNT && showHealth;

      return {
        saveAge: true,
        monthlyBenefit: true,
        moneyPool: true,
        eliminationPeriod: true,
        homeHealthCareWaiver: true,
        returnOfPremium: true,
        payDuration: true,
        sharedCare,
        jointPremiumWaiver,
        inflationPercentage: true,
        partnerDiscount: true,
        insuredHealth: showHealth,
        jointInsuredHealth,
        discount: true,
        mode: true,
      };
    },
    toQuoteRequest({ eAppParams = {}, insured, otherInsured, agentId }) {
      const paramObj = eAppParams;
      paramObj.otherInsured = otherInsured;
      return {
        agent: { id: agentId },
        compare: false,

        ...insuredQuoteData(insured),

        selected_type: eAppParams.selected_type,
        state: eAppParams.state,
        mode: eAppParams.mode,
        ...this.toQuoteScenario(paramObj),
      };
    },
    toQuoteScenario(paramObj) {
      let pool_of_money = paramObj.pool_of_money;
      let benefit_period = paramObj.benefit_period;
      if ([pool_of_money, benefit_period].includes(-1)) {
        pool_of_money = -1;
        benefit_period = -1;
      }
      const body = {
        categories: this.defaults.categories,
        save_age: paramObj.save_age,

        monthly_benefit: paramObj.monthly_benefit,
        pool_of_money,
        benefit_period,
        elimination_period: paramObj.elimination_period,
        home_health_care_waiver: paramObj.home_health_care_waiver,
        shared_care: paramObj.shared_care,
        joint_waiver_of_premium: paramObj.joint_waiver_of_premium,
        inflation_percentage: paramObj.inflation_percentage,
        pay_duration: paramObj.pay_duration,
        return_of_premium_rider: paramObj.return_of_premium_rider,
        discount: paramObj.discount,
        partner_discount: paramObj.partner_discount,
      };

      if (paramObj.partner_discount === 'both') {
        body.joint_party = insuredQuoteData(paramObj.otherInsured);
        body.support_joint = true;
      }

      return body;
    },
    eAppParser(model = {}) {
      const monthlyBenefit = closestCalc(
        model.monthly_benefit || this.defaults.monthly_benefit,
        MONTHLY_BENEFITS,
      );

      let partnerDiscount = model.partner_discount;
      if (!this.partnerDiscountOptions.some(({ value }) => value === partnerDiscount))
        partnerDiscount = this.defaults.partner_discount;

      let inflationPercentage = model.inflation_percentage;

      if (!this.inflationOptions.some(({ value }) => value === inflationPercentage))
        inflationPercentage = this.defaults.inflation_percentage;

      let homeHealthCareWaiver = model?.home_health_care_waiver;
      if (!isBoolean(homeHealthCareWaiver))
        homeHealthCareWaiver = this.defaults.home_health_care_waiver;

      let eliminationPeriod = model?.elimination_period;
      if (!ELIMINATION_PERIODS.includes(eliminationPeriod))
        eliminationPeriod = this.defaults.elimination_period;

      let sharedCare = model?.shared_care;
      if (!isBoolean(sharedCare)) sharedCare = this.defaults.shared_care;

      let payDuration = +model?.pay_duration;
      if (!this.payDurationOptions.some(({ value }) => value === payDuration))
        payDuration = this.defaults.pay_duration;

      let returnOfPremium = model?.return_of_premium_rider;
      if (!this.returnOfPremiumOptions.some(({ value }) => returnOfPremium === value)) {
        returnOfPremium = this.defaults.return_of_premium_rider;
      }

      let discount = model?.discount;
      if (!DISCOUNT_OPTIONS.some(({ value }) => value === discount)) {
        discount = this.defaults.discount;
      }

      let saveAge = model.save_age;
      if (!isBoolean(saveAge)) saveAge = this.defaults.save_age;

      let benefit_period = this.defaults.benefit_period;
      if (model?.benefit_period) {
        benefit_period = closestCalc(
          model.benefit_period,
          BENEFIT_PERIOD_OPTIONS.map(({ value }) => value),
        );
      }

      let pool_of_money = closestCalc(
        model.pool_of_money || this.defaults.pool_of_money,
        MONEY_POOL_OPTIONS,
      );

      if ([pool_of_money, benefit_period].includes(-1)) {
        pool_of_money = -1;
        benefit_period = -1;
      }

      let joint_waiver_of_premium = model?.joint_waiver_of_premium;
      if (![true, false].includes(joint_waiver_of_premium)) {
        joint_waiver_of_premium = this.defaults.joint_waiver_of_premium;
      }

      return {
        benefit_period,
        monthly_benefit: monthlyBenefit,
        pool_of_money,
        elimination_period: eliminationPeriod,
        home_health_care_waiver: homeHealthCareWaiver,
        shared_care: sharedCare,
        inflation_percentage: inflationPercentage,
        partner_discount: partnerDiscount,
        pay_duration: payDuration,
        return_of_premium_rider: returnOfPremium,
        discount,
        save_age: saveAge,
        joint_waiver_of_premium,
      };
    },
    giveParamsSuggestion(age, params) {
      if (age < 30) {
        return 'The minimum age for Long Term Care Insurance is 30';
      } else if (age > 79) {
        return 'The maximum age for Long Term Care Insurance is 79';
      } else if (params.monthly_benefit < 3000) {
        return 'Consider increasing your monthly benefit to $3,000';
      }
      return '';
    },
  };
}
