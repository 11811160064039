import { steps, stepToRouteName } from '#src/enumerations/step-enumeration.js';
import { defineStore } from '#src/stores/state-wrapper.js';
import { REQS } from '#src/enumerations/data-requirements.js';

import { useStepGenderStore } from '#src/stores/step-gender.js';
import { useStepCalculatorStore } from '#src/stores/step-calculator.js';
import { useStepReferralStore } from '#src/stores/step-referral.js';
import { useFlowStore } from '#src/stores/flow.js';
import { useNameForm } from '#src/components/steps/name/name-form.composable.js';

export const useStepNameStore = (pinia, hot) =>
  defineStore('step-name', {
    state: () => ({
      inactive: false,
    }),
    getters: {
      step: () => steps.NAME,
      routeName: (s) => stepToRouteName[s.step],
      next: () => {
        const gender = useStepGenderStore(pinia);
        if (gender.inactive) return gender.next;
        return gender.step;
      },
      previous() {
        const flow = useFlowStore(pinia);
        if (flow.initialStepIsReferral) return useStepReferralStore(pinia).step;
        if (flow.initialStepIsCalculator) return useStepCalculatorStore(pinia).step;
        return null;
      },
    },
    actions: {
      generateForm(module, args) {
        return useNameForm(pinia, module, args);
      },
      setStepRequirements(srq) {
        this.inactive = !srq[REQS.INSURED_NAME];
      },
    },
  })(pinia, hot);
