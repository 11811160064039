import { steps, stepToRouteName } from '#src/enumerations/step-enumeration.js';
import { defineStore } from '#src/stores/state-wrapper.js';
import { useStepBenefitEstimationStore } from '#src/stores/step-benefit-estimation.js';
import { useStepPreQuoteInformationStore } from '#src/stores/step-pre-quote-information.js';
import { useStepBirthdateStore } from '#src/stores/step-birthdate.js';
import { useStepLifePriorityStore } from '#src/stores/step-life-priority.js';
import { useQuotingStore } from '#src/stores/quoting.js';
import { useEappStore } from '#src/stores/electronic-application.js';
import { usePrimaryInsuredStore, useJointInsuredStore } from '#src/stores/insured.js';
import { useQuoteFetch } from '#src/composables/quote-fetch.composable.js';
import { useInstanceSettingsStore } from '#src/stores/instance-settings.js';
import { useStepContactStore } from '#src/stores/step-contact.js';
import { useStepCompareStore } from '#src/stores/step-compare.js';
import { PAYOUT_AMOUNT_SOLVE } from '#src/structures/quote-params/IncomeRider.js';

// import { STANDARD_HEALTH } from '#src/data/healthTypes.js';

import {
  categoryToConstantProduct,
  CONCEPTS,
  PRODUCTS,
  ProductType,
  UL_TYPES,
} from '#src/structures/ProductType.js';
import {
  MAX_NON_MEC_PREMIUM,
  MIN_NON_MEC_DEATH_BENEFIT,
} from '#src/structures/quote-params/UniversalLife.js';

import {
  mdiCash,
  mdiCashMultiple,
  mdiClockFast,
  mdiSack,
  mdiSortAscending,
  mdiStar,
  mdiStethoscope,
} from '@mdi/js';
import { InternalQuote } from '#src/structures/Quote.js';

const SORTING_METHODS = {
  MAX_INCOME: ({ quote }) => {
    if (quote.income_amount || quote.income_amount === 0) {
      return -1 * quote.income_amount;
    }

    if (quote.distributions_yearly_level || quote.distributions_yearly_level === 0) {
      return -1 * quote.distributions_yearly_level;
    }
    return Number.MAX_VALUE;
  },
  MIN_PREMIUM: ({ quote }) => {
    let premium = quote.premium;
    if (quote.product.multiple_policies && quote.partner_discount === 'both') {
      premium += quote.joint_insured_premium || 0;
    }
    return premium;
  },
  MIN_APPROVAL_TIME: ({ quote }) => {
    if (quote.carrier?.approval_time || quote.carrier?.approval_time === 0) {
      return quote.carrier.approval_time;
    }
    return Number.MAX_VALUE;
  },
  NO_EXAM: ({ quote }) => {
    if (!quote.exam_required) return 1;
    if (quote.exam_required === 'possibly') return 2;
    return 3;
  },
  MAX_CASH_VALUE: ({ quote }) => {
    const guaranteed = quote?.guaranteed_account_values;
    const hasEnoughGuaranteedValues = guaranteed?.length >= 20;

    const nonGuaranteed = quote?.non_guaranteed_account_values;
    const hasEnoughNonGuaranteedValues = nonGuaranteed?.length >= 20;

    if (hasEnoughGuaranteedValues && hasEnoughNonGuaranteedValues) {
      return -1 * Math.max(guaranteed[19], nonGuaranteed[19]);
    } else if (hasEnoughGuaranteedValues) {
      return -1 * guaranteed[19];
    } else if (hasEnoughNonGuaranteedValues) {
      return -1 * nonGuaranteed[19];
    }
    return Number.MAX_VALUE;
  },
  PREFERRED: ({ quote }) => (quote.carrier.preferred ? 1 : 2),
  MAX_DEATH_BENEFIT: ({ quote }) => {
    if (quote.initial_death_benefit || quote.initial_death_benefit === 0) {
      return quote.initial_death_benefit * -1;
    }
    return Number.MAX_VALUE;
  },
  VALIDATED_BUILD: ({ quote }) => (quote.validated_build ? 1 : 2),
};

export const SORT = {
  PREMIUM: 'PREMIUM',
  INCOME: 'INCOME',
  DEATH_BENEFIT: 'DEATH_BENEFIT',
  CASH_VALUE: 'CASH_VALUE',
  APPROVAL_TIME: 'APPROVAL_TIME',
  NO_EXAM: 'NO_EXAM',
  PREFERRED: 'PREFERRED',
};
const SORTING_OPTIONS = {
  [SORT.PREMIUM]: {
    key: SORT.PREMIUM,
    icon: mdiCash,
    text: 'Lowest Price',
    methods: [SORTING_METHODS.MIN_PREMIUM],
  },
  [SORT.INCOME]: {
    key: SORT.INCOME,
    icon: mdiCashMultiple,
    text: 'Highest Income',
    methods: [SORTING_METHODS.MAX_INCOME, SORTING_METHODS.MAX_CASH_VALUE],
  },
  [SORT.DEATH_BENEFIT]: {
    key: SORT.DEATH_BENEFIT,
    icon: mdiSortAscending,
    text: 'Highest Death Benefit',
    methods: [SORTING_METHODS.MAX_DEATH_BENEFIT, SORTING_METHODS.MAX_CASH_VALUE],
  },
  [SORT.CASH_VALUE]: {
    key: SORT.CASH_VALUE,
    icon: mdiSack,
    text: 'Highest Cash Value',
    methods: [SORTING_METHODS.MAX_CASH_VALUE],
  },
  [SORT.APPROVAL_TIME]: {
    key: SORT.APPROVAL_TIME,
    icon: mdiClockFast,
    text: 'Fastest Approval Time',
    methods: [SORTING_METHODS.MIN_APPROVAL_TIME],
  },
  [SORT.NO_EXAM]: {
    key: SORT.NO_EXAM,
    icon: mdiStethoscope,
    text: 'No Physical Exam',
    methods: [SORTING_METHODS.NO_EXAM],
  },
  [SORT.PREFERRED]: {
    key: SORT.PREFERRED,
    icon: mdiStar,
    text: 'Preferred',
    methods: [SORTING_METHODS.PREFERRED],
  },
};

export const useStepQuoteStore = (pinia, hot) =>
  defineStore('step-quote', {
    state: () => ({
      initialPreferredSortKey: null,
      nextText: '',
      quoters: [],
      quotes: [],
      hints: [],
      activeSort: null,
      loaderProgress: 0,
      expectedRequests: 0,
      quoteParamsChanged: false,
    }),
    getters: {
      step: () => steps.QUOTE,
      routeName: (s) => stepToRouteName[s.step],
      next() {
        const contact = useStepContactStore(pinia);
        return contact.step;
      },
      previous() {
        const birthdate = useStepBirthdateStore(pinia);
        if (birthdate.next === this.step) {
          if (birthdate.inactive) return birthdate.previous;
          return birthdate.step;
        }

        const benefit = useStepBenefitEstimationStore(pinia);
        if (!benefit.inactive && benefit.next === this.step) {
          return benefit.step;
        }

        const preQuoteInfo = useStepPreQuoteInformationStore(pinia);
        if (!preQuoteInfo.inactive && preQuoteInfo.next === this.step) {
          return preQuoteInfo.step;
        }

        const priority = useStepLifePriorityStore(pinia);
        if (!priority.inactive && priority.next === this.step) {
          return priority.step;
        }

        const compare = useStepCompareStore(pinia);
        return compare.step;
      },
      // quote page util ~
      firstNonVitalityJohnHancockQuote() {
        return this.quotes.find(
          ({ quote }) => quote.product.vitality_rider === false && quote.carrier.id === 77,
        );
      },
      firstVitalityQuote() {
        return this.quotes.find(({ quote }) => quote.product.vitality_rider === true);
      },
      firstRecommendedProduct() {
        const product = this.quotes.find(
          ({ quote }) => quote.carrier.preferred && quote.rank !== 1,
        );
        return product?.quote;
      },
      quickestApprovalTime() {
        if (!this.quotes.length) return {};
        let quickestQuote = 0;
        let quickestApprovalTime = Number.MAX_VALUE;

        this.quotes.forEach(({ quote }, index) => {
          if (quickestApprovalTime >= quote.carrier.approval_time) return;
          quickestApprovalTime = quote.carrier.approval_time;
          quickestQuote = index;
        });
        return this.quotes[quickestQuote]?.quote;
      },
      firstNoExamQuote() {
        return this.quotes.find(({ quote }) => quote.exam_required !== true && quote.rank !== 1)
          ?.quote;
      },
      defaultSortKey() {
        const quoting = useQuotingStore(pinia);
        const eApp = useEappStore(pinia);
        const params = quoting.rollbackPoint;
        if (!params?.selected_type) return SORT.PREMIUM;
        const productType = new ProductType(params.selected_type);

        if (params.solve === PAYOUT_AMOUNT_SOLVE) return SORT.INCOME;
        if (productType.renderRules(params, { conversion: eApp.conversion }).incomeSolve) {
          const cashValueSolves = [MIN_NON_MEC_DEATH_BENEFIT, MAX_NON_MEC_PREMIUM];

          if (params.income_solve === 'Max') return SORT.INCOME;
          else if (cashValueSolves.includes(params.solve) && this.canCashValueSort) {
            return SORT.CASH_VALUE;
          }
        }

        const isDeathBenefitSolve = productType?.solves?.death_benefit_solves?.some(
          ({ value }) => value === params.solve,
        );

        if (isDeathBenefitSolve) return SORT.DEATH_BENEFIT;
        return SORT.PREMIUM;
      },
      canNoExamSort() {
        return this.quotes.some(({ quote }) => quote.exam_required !== true);
      },
      canPreferredSort() {
        return this.quotes.some(({ quote }) => quote.carrier.preferred);
      },
      canCashValueSort() {
        return this.quotes.some(({ quote }) => {
          const hasEnoughGuaranteedValues = quote?.guaranteed_account_values?.length >= 20;
          const hasEnoughNonGuaranteedValues = quote?.non_guaranteed_account_values?.length >= 20;

          return hasEnoughGuaranteedValues || hasEnoughNonGuaranteedValues;
        });
      },
      availableSorts() {
        const sortsAvailable = {
          [this.defaultSortKey]: SORTING_OPTIONS[this.defaultSortKey],
        };
        sortsAvailable.APPROVAL_TIME = SORTING_OPTIONS.APPROVAL_TIME;

        if (this.canNoExamSort) {
          sortsAvailable.NO_EXAM = SORTING_OPTIONS.NO_EXAM;
        }
        if (this.canCashValueSort) {
          sortsAvailable.CASH_VALUE = SORTING_OPTIONS.CASH_VALUE;
        }
        if (this.canPreferredSort) {
          sortsAvailable.PREFERRED = SORTING_OPTIONS.PREFERRED;
        }
        return Object.values(sortsAvailable);
      },
    },
    actions: {
      addQuote({ quote, replaceLoader = false }) {
        const categories = categoryToConstantProduct(quote.product.category);

        if (
          categories.some((c) => [PRODUCTS.TERM, PRODUCTS.ROP_TERM, PRODUCTS.NON_MED].includes(c))
        ) {
          quote.term_duration = quote.pay_duration;
        }
        const quoteWithHelpers = {
          primary: false,
          rank: null,
          type: null,
          preferred: false,
          downloadingPdf: false,
          quote: InternalQuote(quote),
        };

        if (!replaceLoader) this.quotes.push(quoteWithHelpers);
        const index = this.quotes.findIndex((v) => v.quote.loader);
        this.quotes[index] = quoteWithHelpers;
      },
      removeAllLoaderQuotes() {
        this.quotes = this.quotes.filter(({ quote }) => !quote.loader);
      },
      setAllQuotes(quotes) {
        this.quotes = quotes;
      },
      resetQuotes() {
        this.quotes = [];
      },
      filterDuplicateIds() {
        const uniqueQuotes = {};

        this.quotes.forEach((fullQuote) => {
          if (!uniqueQuotes[fullQuote.quote.product.id]) {
            uniqueQuotes[fullQuote.quote.product.id] = fullQuote;
            return;
          }
          if (!uniqueQuotes[fullQuote.quote.product.id].quote.illustration_available) {
            uniqueQuotes[fullQuote.quote.product.id] = fullQuote;
          }
        });
        this.setAllQuotes(Object.values(uniqueQuotes));
      },
      filterDualInsuredQuotes() {
        const quotes = [];

        const primaryQuotes = [];
        const jointQuotes = [];

        this.quotes.forEach((q) => {
          if (q.quote.joint) jointQuotes.push(q);
          else primaryQuotes.push(q);
        });

        primaryQuotes.forEach(({ quote: pQuote, ...allElse }) => {
          const jQuote = jointQuotes.find(({ quote }) => quote.product.id === pQuote.product.id);

          if (!jQuote) return;
          const jointInsuredQuote = jQuote.quote;

          quotes.push({
            ...allElse,
            quote: InternalQuote({
              ...pQuote,
              joint_insured_premium: jointInsuredQuote.premium,
              joint_insured_quote_id: jointInsuredQuote.id,
              joint_insured_validated_build: jointInsuredQuote.validated_build,
              joint_insured_validated_rating: jointInsuredQuote.validated_rating,
              joint_insured_validated_rating_error: jointInsuredQuote.validated_rating_error,
              joint_insured_validated_smoking_types: jointInsuredQuote.validated_smoking_types,
            }),
          });
        });

        this.setAllQuotes(quotes);
      },
      prequoteSetup() {
        const quotingStore = useQuotingStore(pinia);
        const instanceSettings = useInstanceSettingsStore(pinia);
                
        if (!quotingStore.params.selected_type) {
          quotingStore.params.selected_type = instanceSettings.available[0].type;
        } else if (!instanceSettings.products[quotingStore.params.selected_type] && !instanceSettings.concepts[quotingStore.params.selected_type]) {
          quotingStore.params.selected_type = instanceSettings.available[0].type;
        }

        let face_amount = quotingStore.params.face_amount;

        if (!face_amount) {
          const sliderValues = instanceSettings.default_slider_values;
          const defaultAmountsByType = {
            [PRODUCTS.ACCIDENTAL_DEATH]: sliderValues.accidental_death,
            [PRODUCTS.FINAL_EXPENSE]: sliderValues.fex,
          };

          face_amount = defaultAmountsByType[quotingStore.params.selected_type];
          if (!face_amount) face_amount = sliderValues.permanent;
        }

        const productType = new ProductType(quotingStore.params.selected_type);

        const insured = usePrimaryInsuredStore(pinia);
        // if (!insured.health.model) insured.health.load(STANDARD_HEALTH);
        const params = productType.eAppParser({
          ...quotingStore.params,
          face_amount,
          age: insured.age,
        });

        quotingStore.initializeQuoteParams(params);
      },
      async fetchAppQuotes() {
        this.quoteUnsub();
        let initialSortKey = null;
        if (this.initialPreferredSortKey) initialSortKey = this.initialPreferredSortKey;
        this.$reset();
        this.initialPreferredSortKey = initialSortKey;
        const quoting = useQuotingStore(pinia);
        quoting.setRollbackPoint();

        const insured = usePrimaryInsuredStore(pinia);
        const jointInsured = useJointInsuredStore(pinia);

        await this.fetchQuotes(insured, jointInsured);
        return this.handleLoadedQuotes();
      },

      fetchQuotes(insured, otherInsured) {
        return new Promise((resolve, reject) => {
          try {
            const instanceSettings = useInstanceSettingsStore(pinia);
            const quoting = useQuotingStore(pinia);

            let productType = new ProductType(quoting.params.selected_type);

            const termConceptItems = [PRODUCTS.TERM, PRODUCTS.NON_MED];
            const ulConceptItems = [CONCEPTS.ALL_UL, ...UL_TYPES];

            if (termConceptItems.includes(quoting.params.selected_type)) {
              productType = instanceSettings.verboseConcepts[CONCEPTS.TERM];
            } else if (ulConceptItems.includes(quoting.params.selected_type)) {
              productType = instanceSettings.verboseConcepts[CONCEPTS.ALL_UL];
            }

            const eApp = useEappStore(pinia);
            const quoter = useQuoteFetch({
              pinia,
              onHint: (v) => this.hints.push(v),
              onProcessAdd: () => this.expectedRequests++,
              onProcessRemove: () => this.loaderProgress++,
              onQuoteAdd: ({ quote }) => {
                if (!quote.id) return;
                this.addQuote({ quote });
              },
              onEnd: () => {
                quoter.disconnect();
                resolve();
              },
            });
            const params = productType.toQuoteRequest({
              eAppParams: quoting.params,
              insured,
              otherInsured,
              agentId: instanceSettings.agent.id,
              eAppId: eApp.eAppUuid,
            });

            this.quoters.push(quoter);
            let timeout = setTimeout(() => {
              quoter.disconnect();
              resolve();
            }, 300 * 1000);
            quoter.fetch(params).catch((e) => {
              clearTimeout(timeout);
              reject(e);
            });
          } catch (e) {
            reject(e);
          }
        });
      },
      handleLoadedQuotes() {
        const eApp = useEappStore(pinia);
        if (eApp.isDualInsured) this.filterDualInsuredQuotes();
        else this.filterDuplicateIds();

        if (!this.quotes.length) return;
        // change default sort when coming from benefit estimation
        let defaultSort = this.availableSorts[0].key;
        if (this.initialPreferredSortKey) {
          defaultSort = this.initialPreferredSortKey;
          this.initialPreferredSortKey = null;
        }
        this.sortQuotes(defaultSort);
        let quote = this.quotes[0].quote;
        const primaryQuoteId = eApp.selectedQuote.product.id;
        if (primaryQuoteId) {
          const foundPrimaryQuote = this.quotes.find((val) => {
            return `${val.quote.product.id}` === `${primaryQuoteId}`;
          });
          if (foundPrimaryQuote) quote = foundPrimaryQuote.quote;
        }

        return quote;
      },
      quoteUnsub() {
        this.quoters.forEach((q) => {
          if (q?.disconnect) q.disconnect();
        });
      },
      sortQuotes(key) {
        this.activeSort = SORTING_OPTIONS[key];
        const sort = { ...this.activeSort };

        this.quotes.sort((a, b) => {
          let sortIndex = 0;
          let delta;

          const methods = [SORTING_METHODS.VALIDATED_BUILD, ...sort.methods];

          // always add the default sort as a backup unless it is the default sort
          if (key !== this.defaultSortKey) {
            methods.push(...SORTING_OPTIONS[this.defaultSortKey].methods);
          }
          do {
            const method = methods[sortIndex++];
            delta = method(a) - method(b);
          } while (sortIndex < methods.length && delta === 0);
          return delta;
        });

        this.quotes.forEach((_, index) => {
          this.quotes[index].quote.rank = index + 1;
        });
      },
    },
  })(pinia, hot);
